/**
    Directory structure:

    /settings: Global variables, site-wide settings, config switches, etc.
    /tools: Site-wide mixins and functions.
    /generic: Low-specificity, far-reaching rulesets (e.g. resets).
    /elements: Unclassed HTML elements (e.g. a {}, blockquote {}, address {}).
    /objects: Objects, abstractions, and design patterns (e.g. .o-layout {}).
    /components: Discrete, complete chunks of UI (e.g. .c-carousel {}).
    /utilities: High-specificity, very explicit selectors. Overrides and helper classes (e.g. .u-hidden {}).

*/

/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Global...............Project-wide variables and settings.
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............Media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 *
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Hide.................Helper classes to hide content
 */

// FONTS
@import 'fonts/fonts.pfdindisplay-pro';
@import 'fonts/fonts.arlrdbd';
@import 'fonts/fonts.opensans-extrabold';

// SETTINGS
@import 'settings/settings.config';
@import 'settings/settings.mq';
@import '~inuitcss/settings/settings.core';
@import 'settings/settings.colors';
@import 'settings/settings.global';

// TOOLS
@import '~inuitcss/tools/tools.font-size';
@import '~inuitcss/tools/tools.clearfix';
@import '~inuitcss/tools/tools.hidden';
@import '~sass-mq/mq';
@import 'tools/tools.aliases';
@import 'tools/tools.global';
@import 'tools/tools.navigation';

// GENERIC
@import '~inuitcss/generic/generic.box-sizing';
@import '~inuitcss/generic/generic.normalize';
@import '~inuitcss/generic/generic.reset';
@import '~inuitcss/generic/generic.shared';
@import 'generic/generic.screens';

// ELEMENTS
@import '~inuitcss/elements/elements.page';
@import 'elements/elements.page';
@import '~inuitcss/elements/elements.headings';
@import '~inuitcss/elements/elements.images';
@import '~inuitcss/elements/elements.tables';
// @import 'elements/elements.links';
// @import 'elements/elements.quotes';

// OBJECTS
@import '~inuitcss/objects/objects.wrapper';
@import '~inuit-flexgrid/objects/objects.grid';
@import '~inuitcss/objects/objects.media';
@import '~inuitcss/objects/objects.flag';
@import '~inuitcss/objects/objects.list-bare';
@import '~inuitcss/objects/objects.list-inline';
@import '~inuitcss/objects/objects.box';
@import '~inuitcss/objects/objects.block';
@import '~inuitcss/objects/objects.ratio';
@import '~inuitcss/objects/objects.crop';
@import '~inuitcss/objects/objects.table';
@import '~inuitcss/objects/objects.pack';

// COMPONENTS
@import 'components/components.avatar';
@import 'components/components.app';
@import 'components/components.header';
@import 'components/components.footer';
@import 'components/components.wrapper';
@import 'components/components.loader';
@import 'components/components.buttons';
@import 'components/components.redeem-screen';
@import 'components/components.input';
@import 'components/components.help-screen';
@import 'components/components.gifts-screen';
@import 'components/components.gift';
@import 'components/component.gift-details';
@import 'components/components.profile-screen';
@import 'components/components.points-screen';


// UTILITIES
@import '~inuitcss/utilities/utilities.widths';
@import '~inuitcss/utilities/utilities.headings';
@import '~inuitcss/utilities/utilities.spacings';
@import '~inuitcss/utilities/utilities.responsive-spacings';
@import '~inuitcss/utilities/utilities.hide';
@import 'utilities/utilities.containers';
@import 'utilities/utilities.helpers';

// PAGES
@import 'pages/page.home';
@import 'pages/page.tutorial';
@import 'pages/page.privacy-policy';
@import 'pages/page.privacy-policy-document';
